@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
body,
* {
  cursor: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #ffffff;
  overflow-x: hidden;
  height: 100%;
  font-family: 'Outfit', sans-serif !important;
}
#root {
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6418c3;
}

.purple-bg {
  background: linear-gradient(90deg, #ec83bb 0%, #b664db 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-clip: text;
  animation: gradientAnimation 2s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@media screen and (max-width: 659px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

a {
  text-decoration: none;
}
/* css */
.section {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0;
  will-change: opacity, transform;
}

.section:first-of-type {
  opacity: 1;
  position: relative;
}

.scroll-downs {
  position: fixed;
  right: 0;
  bottom: 10px;
  left: 0;
  margin: auto;
  z-index: 99;
  width: 34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 5px 12px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
.cursor {
  position: fixed;
  pointer-events: none;
  width: 19px;
  height: 19px;
  z-index: 9999;
  transform: translate(-50%, -50%);
}
